/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable eqeqeq */

import React, { Component } from 'react';

import M from 'materialize-css';
import Moment from 'moment';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import CheckIcon from '@material-ui/icons/Check';
import { BsCheck2Circle } from 'react-icons/bs';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as CpfValidator from 'cpf';
import { Creators as RegisterAction } from '../../store/ducks/register';

import Factories from '../../factories';
import Constants from '../../factories/constants';
import Loading from '../../components/Loading';
import Progress from '../../components/Progress';
import Logo from '../../assets/img/logo_psicomanager_white.png';
import Button from '../../components/Register/Button/Button';
import Input from '../../components/Register/Input/Input';
import DropDown from '../../components/Register/DropDown/DropDown';
import MaskInput from '../../components/Register/MaskedInput/MaskInput';
import ProgressBarRegister from '../../components/Register/ProgressBarRegister/ProgressBarRegister';

import './styles.css';

import { TextfieldCountry } from '../../components/TextfieldCountry/TextfieldCountry';
import { paisesInfo } from '../../utils/paises';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rg: '',
      cpf: '',
      cep: '',
      sexo: '0',
      cod_uf: 0,
      endereco: '',
      endereco_nro: '',
      endereco_complemento: '',
      bairro: '',
      tel_pessoal: '',
      profissao: '',
      data_nascimento: null,
      cod_profissional: 0,
      nome_parente: '',
      tel_parente: '',
      nome_paciente: '',
      email_paciente: '',
      nome_cidade: '',
      naturalidade: '',
      grau_instrucao: 0,
      cpf_responsavel: '',
      nome_responsavel: '',
      lista_onde_conheceu: [],
      cod_onde_conheceu: 0,
      loading: false,
      currentStep: 1,
      totalSteps: 4,
      professionalIsOpend: false,
      genderIsOpend: false,
      scholarityIsOpend: false,
      stateIsOpend: false,
      pais: 'Brasil',
      paisIsOpen: false,
      whereHasKnowIsOpend: false,
      nome_profissional: 'Selecione',
      gender: 'Selecione',
      scholarity: 'Selecione',
      state: 'Selecione',
      whereHasKnow: 'Selecione',
      nome_social: '',
      shouldShowSocialName: false,
      professionalArray: [],
      schoolingArray: Constants.SCHOOLING,
      statesArray: Constants.STATES,
      paises: paisesInfo,
      WhereHasMeetArray: [],
      ddi: '55',
      completePhoneInfo: '',
    };
  }

  componentDidMount() {
    M.AutoInit();
    const { match, getClinicRequest } = this.props;
    const { params } = match;

    window.location.replace(
      `https://app2.psicomanager.com/cadastro-online/${params.id}`
    );

    /** 197||05ed5cc4710399c54f094907e7db3274 */
    getClinicRequest(params.id);
  }

  componentDidUpdate() {
    M.updateTextFields();
  }

  static getDerivedStateFromProps(props) {
    if (props.register.msg) {
      if (!props.register.msg.error) {
        return {
          currentStep: 4,
          loading: false,
        };
      }
      return { loading: false };
    }
    return null;
  }

  handleChangeInput = e => {
    const { name, value } = e.target;

    if (name === 'endereco_nro' || name === 'tel_parente') {
      if (!isNaN(e.target.value[e.target.value.length - 1])) {
        this.setState({ [name]: value });
      }
      return;
    }

    this.setState({ [name]: value });
  };

  handleChangeMaskedInput = (e, name) => {
    this.setState({ [name]: e.target.value });
  };

  handleInputDate = date => {
    this.setState({ data_nascimento: date ? new Date(date) : null });
  };

  handleSelectOnClick = (e, value, name, label) => {
    this.setState({ [name]: value });

    if (label) {
      this.setState({ [label]: e.target.innerText });
    }

    if (name === 'sexo' && value === 'O') {
      this.setState({
        shouldShowSocialName: true,
      });
    } else if (name === 'sexo' && value !== 'O') {
      this.setState({
        shouldShowSocialName: false,
      });
    }

    if (name === 'cod_profissional') {
      const { register } = this.props;
      if (!Factories.isEmptyObject(register.clinic)) {
        const data = register.clinic.professionals.data.filter(
          (element, key, array) => {
            return array[key].id === value;
          }
        );
        this.setState({
          lista_onde_conheceu: data[0].knowUs,
          WhereHasMeetArray: data[0].knowUs,
        });
      }
    }
  };

  searchOnChangeText = (e, searchParameter, array, setArray) => {
    const { value } = e.target;

    const data = array.filter(element => {
      return element[searchParameter]
        .toLowerCase()
        .includes(value.toLowerCase());
    });
    this.setState({ [setArray]: data });
  };

  handleNext = () => {
    let { currentStep } = this.state;
    const { totalSteps } = this.state;

    if (currentStep === 2) {
      const {
        nome_paciente,
        data_nascimento,
        email_paciente,
        cpf,
        completePhoneInfo,
      } = this.state;

      const telWithoutSpecialCharacter = completePhoneInfo.replace(
        /[^\d]/g,
        ''
      );
      const isBrTel = telWithoutSpecialCharacter.slice(0, 2) === '55';

      if (
        (isBrTel && telWithoutSpecialCharacter.length < 11) ||
        completePhoneInfo.length === 0
      ) {
        M.toast({
          html: 'Insira um telefone válido!',
          displayLength: 3000,
        });

        return false;
      }

      if (nome_paciente === '' || nome_paciente.length < 6) {
        M.toast({
          html: 'Insira um nome de paciente com no mínimo 06 caracteres!',
          displayLength: 3000,
        });
        return false;
      }

      if (data_nascimento === null) {
        M.toast({
          html: 'Data de nascimento é um campo de preenchimento obrigatório!',
          displayLength: 3000,
        });
        return false;
      }

      if (!Moment(data_nascimento, 'DD/MM/YYYY', true).isValid()) {
        M.toast({
          html: 'Insira uma data de nascimento válida!',
          displayLength: 3000,
        });
        return false;
      }

      if (!this.emailIsValid(email_paciente.trim())) {
        M.toast({
          html: 'Insira um e-mail válido!',
          displayLength: 3000,
        });
        return false;
      }

      if (!this.cpfIsValid(cpf)) {
        this.setState({ loading: false });
        M.toast({
          html: 'Insira um CPF válido!',
          displayLength: 3000,
        });
        return false;
      }
    }

    if (currentStep !== totalSteps) {
      currentStep += 1;
      this.setState({ currentStep });
    }

    return true;
  };

  handlePrev = () => {
    let { currentStep } = this.state;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({ currentStep });
  };

  shouldNextButtonDisable = () => {
    const { currentStep, cod_profissional } = this.state;
    switch (currentStep) {
      case 1:
        return parseInt(cod_profissional, 10) === 0;
      case 2:
        return false;
      default:
        return false;
    }
  };

  emailIsValid = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  cpfIsValid = cpf => {
    return CpfValidator.isValid(cpf);
  };

  formIsValid = form => {
    if (form.email_paciente && !this.emailIsValid(form.email_paciente.trim())) {
      this.setState({ loading: false });
      M.toast({
        html: 'Insira um e-mail válido!',
        displayLength: 3000,
      });
      return false;
    }

    if (form.cpf && !this.cpfIsValid(form.cpf)) {
      this.setState({ loading: false });
      M.toast({
        html: 'Insira um CPF válido!',
        displayLength: 3000,
      });
      return false;
    }

    if (form.cpf_responsavel && !this.cpfIsValid(form.cpf_responsavel)) {
      this.setState({ loading: false });
      M.toast({
        html: 'Insira um CPF de responsável válido!',
        displayLength: 3000,
      });
      return false;
    }

    if (!form.nome_paciente) {
      this.setState({ loading: false });
      M.toast({
        html: 'O nome do paciente é um campo obrigatório!',
        displayLength: 3000,
      });
      return false;
    }

    if (form.data_nascimento === null || form.data_nascimento === '') {
      this.setState({ loading: false });
      M.toast({
        html: 'Data de nascimento é um campo de preenchimento obrigatório!',
        displayLength: 3000,
      });
      return false;
    }

    return true;
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    const { match } = this.props;
    const { params } = match;

    const {
      cod_profissional,
      nome_paciente,
      email_paciente,
      data_nascimento,
      tel_pessoal,
      cpf,
      rg,
      cep,
      endereco,
      endereco_nro,
      endereco_complemento,
      bairro,
      nome_cidade,
      cod_uf,
      grau_instrucao,
      naturalidade,
      profissao,
      nome_responsavel,
      cpf_responsavel,
      nome_parente,
      tel_parente,
      cod_onde_conheceu,
      nome_social,
      sexo,
      ddi,
      pais,
      completePhoneInfo,
    } = this.state;

    const form = {
      cod_clinica: params.id,
      cod_usuario: cod_profissional,
      nome_paciente,
      email_paciente: email_paciente.trim(),
      dt_nascimento: data_nascimento,
      tel_cel_paciente: tel_pessoal,
      cpf,
      rg,
      cep,
      endereco,
      endereco_nro,
      endereco_complemento,
      bairro,
      cidade: nome_cidade,
      uf: cod_uf,
      grau_escolaridade: grau_instrucao,
      naturalidade,
      profissao,
      nome_responsavel,
      cpf_responsavel,
      nome_parente,
      tel_parente,
      cod_onde_conheceu,
      nome_social,
      sexo,
      ddi: `+${ddi}`,
      localidade: paisesInfo.find(item => item.id === pais).id,
    };

    if (this.formIsValid(form)) {
      const { postInsertRequest } = this.props;
      postInsertRequest(form);
    }
  };

  shoudFinishButtonDisable = () => {
    const {
      cod_profissional,
      nome_paciente,
      email_paciente,
      data_nascimento,
    } = this.state;

    if (
      cod_profissional &&
      nome_paciente &&
      email_paciente &&
      data_nascimento != new Date().toLocaleDateString()
    ) {
      return false;
    }

    return true;
  };

  formatCelular(value, data) {
    this.setState({ tel_pessoal: value.slice(data.dialCode.length) });
    this.setState({ ddi: data.dialCode });
    this.setState({ completePhoneInfo: value });
  }

  renderNextButton() {
    const { currentStep, totalSteps } = this.state;
    if (currentStep < totalSteps - 1) {
      return (
        <Button
          onClick={() => this.handleNext()}
          label="Próximo"
          disabled={this.shouldNextButtonDisable()}
        />
      );
    }
    return null;
  }

  renderPreviousButton() {
    const { currentStep } = this.state;
    if (currentStep !== 1 && currentStep <= 3) {
      return (
        <Button
          onClick={() => this.handlePrev()}
          label="Anterior"
          disabled={false}
        />
      );
    }
    return null;
  }

  renderFinishButton() {
    const { currentStep, totalSteps } = this.state;
    if (currentStep === totalSteps - 1) {
      return (
        <Button
          onClick={() => this.handleSubmit()}
          label="Salvar"
          disabled={this.shoudFinishButtonDisable()}
        />
      );
    }
    return null;
  }

  render() {
    const {
      rg,
      cpf,
      cep,
      sexo,
      cod_uf,
      loading,
      cod_profissional,
      data_nascimento,
      endereco,
      endereco_nro,
      endereco_complemento,
      bairro,
      tel_pessoal,
      profissao,
      tel_parente,
      nome_cidade,
      naturalidade,
      nome_parente,
      email_paciente,
      nome_paciente,
      grau_instrucao,
      cpf_responsavel,
      nome_responsavel,
      cod_onde_conheceu,
      currentStep,
      totalSteps,
      lista_onde_conheceu,
      professionalIsOpend,
      genderIsOpend,
      scholarityIsOpend,
      stateIsOpend,
      paisIsOpen,
      pais,
      whereHasKnowIsOpend,
      nome_profissional,
      gender,
      scholarity,
      state,
      whereHasKnow,
      shouldShowSocialName,
      nome_social,
      professionalArray,
      schoolingArray,
      statesArray,
      paises,
      WhereHasMeetArray,
    } = this.state;

    const { register } = this.props;

    const sexOptions = [
      {
        id: 1,
        value: 'M',
        name: 'Masculino',
      },
      {
        id: 2,
        value: 'F',
        name: 'Feminino',
      },
      { id: 3, name: 'Agênero', value: 'A' },
      { id: 4, name: 'Gênero Fluido', value: 'G' },
      { id: 5, name: 'Não Binário', value: 'N' },
      { id: 6, name: 'Transgênero', value: 'T' },
      { id: 7, name: 'Prefere não informar', value: 'P' },
      {
        id: 8,
        value: 'O',
        name: 'Outro',
      },
    ];

    const Locale = Factories.importDateFnsLocale();
    const sessionTitles = [
      'Dados da clínica',
      'Dados pessoais',
      'Dados de contato',
      'Sucesso',
    ];

    if (Factories.isEmptyObject(register.clinic)) {
      return <Progress />;
    }

    return (
      <div className="main-container">
        <div className="topbar purple">
          <img id="logo" src={Logo} alt="PsicoManager" />
        </div>
        <div className="center-container" style={{ marginBottom: 20 }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
            className="register-container"
          >
            <div
              className="title-container"
              style={{ marginBottom: 5, textAlign: 'center' }}
            >
              <div>
                <h5>{sessionTitles[currentStep - 1]}</h5>
              </div>
            </div>

            {!loading && (
              <ProgressBarRegister
                totalChecks={3}
                lastCheckedId={currentStep}
              />
            )}

            {currentStep === 1 && (
              <DropDown
                label="Profissional"
                small={false}
                subTitle={nome_profissional}
                isOpend={professionalIsOpend}
                setIsOpend={() =>
                  this.setState({
                    professionalIsOpend: !professionalIsOpend,
                  })
                }
                shouldShowSearch
                onKeyDown={e =>
                  this.searchOnChangeText(
                    e,
                    'name',
                    register.clinic.professionals.data,
                    'professionalArray'
                  )
                }
              >
                {professionalArray.length > 0
                  ? professionalArray.map(professional => (
                      <p
                        key={professional.id}
                        onClick={e => {
                          this.handleSelectOnClick(
                            e,
                            professional.id,
                            'cod_profissional',
                            'nome_profissional'
                          );
                          this.setState({
                            professionalIsOpend: !professionalIsOpend,
                          });
                        }}
                      >
                        {professional.name}
                      </p>
                    ))
                  : register.clinic.professionals.data.map(professional => (
                      <p
                        key={professional.id}
                        onClick={e => {
                          this.handleSelectOnClick(
                            e,
                            professional.id,
                            'cod_profissional',
                            'nome_profissional'
                          );
                          this.setState({
                            professionalIsOpend: !professionalIsOpend,
                          });
                        }}
                      >
                        {professional.name}
                      </p>
                    ))}
              </DropDown>
            )}

            {currentStep === 2 && (
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <Input
                  value={nome_paciente}
                  onChange={this.handleChangeInput}
                  Label="Nome"
                  maxLength={255}
                  required
                  type="text"
                  name="nome_paciente"
                  placeholder="Digite aqui"
                />

                <Input
                  value={email_paciente}
                  onChange={this.handleChangeInput}
                  Label="E-mail"
                  maxLength={255}
                  type="text"
                  name="email_paciente"
                  required
                  placeholder="Digite aqui"
                />

                <div className="ddi-birth-container">
                  <TextfieldCountry
                    label="Celular:"
                    value={this.completePhoneInfo}
                    // error={!!formState.errors.tel_cel_paciente}
                    required
                    onChange={(value, data) => this.formatCelular(value, data)}
                  />

                  <MaskInput
                    value={data_nascimento}
                    onChange={e =>
                      this.handleChangeMaskedInput(e, 'data_nascimento')
                    }
                    Label="Data de Nascimento"
                    mask="99/99/9999"
                    required
                    placeholder="__/__/____"
                    middle
                  />
                </div>

                <DropDown
                  label="Gênero"
                  subTitle={gender}
                  isOpend={genderIsOpend}
                  setIsOpend={() =>
                    this.setState({
                      genderIsOpend: !genderIsOpend,
                    })
                  }
                  middle
                >
                  {sexOptions.map(sex => (
                    <p
                      key={sex.id}
                      onClick={e => {
                        this.handleSelectOnClick(
                          e,
                          sex.value,
                          'sexo',
                          'gender'
                        );
                        this.setState({
                          genderIsOpend: !genderIsOpend,
                        });
                      }}
                    >
                      {sex.name}
                    </p>
                  ))}
                </DropDown>

                {shouldShowSocialName && (
                  <Input
                    value={nome_social}
                    onChange={this.handleChangeInput}
                    Label="Nome social"
                    maxLength={255}
                    type="text"
                    name="nome_social"
                    placeholder="Digite aqui"
                    middle
                  />
                )}

                <MaskInput
                  value={cpf}
                  onChange={e => this.handleChangeMaskedInput(e, 'cpf')}
                  Label="CPF"
                  mask="999.999.999-99"
                  placeholder="Digite aqui"
                  required
                  middle
                />

                <Input
                  value={rg}
                  onChange={this.handleChangeInput}
                  Label="RG"
                  maxLength={255}
                  type="text"
                  name="rg"
                  placeholder="Digite aqui"
                  middle
                />

                <Input
                  value={profissao}
                  onChange={this.handleChangeInput}
                  Label="Profissão"
                  maxLength={255}
                  type="text"
                  name="profissao"
                  placeholder="Digite aqui"
                  middle
                />

                <Input
                  value={naturalidade}
                  onChange={this.handleChangeInput}
                  Label="Naturalidade"
                  maxLength={255}
                  type="text"
                  name="naturalidade"
                  placeholder="Digite aqui"
                  middle
                />

                <DropDown
                  label="Grau de escolaridade"
                  subTitle={scholarity}
                  isOpend={scholarityIsOpend}
                  setIsOpend={() =>
                    this.setState({
                      scholarityIsOpend: !scholarityIsOpend,
                    })
                  }
                  middle
                  shouldShowSearch
                  onKeyDown={e =>
                    this.searchOnChangeText(
                      e,
                      'name',
                      Constants.SCHOOLING,
                      'schoolingArray'
                    )
                  }
                >
                  {schoolingArray.map(item => (
                    <p
                      key={item.id}
                      onClick={e => {
                        this.handleSelectOnClick(
                          e,
                          item.id,
                          'grau_instrucao',
                          'scholarity'
                        );
                        this.setState({
                          scholarityIsOpend: !scholarityIsOpend,
                        });
                      }}
                    >
                      {item.name}
                    </p>
                  ))}
                </DropDown>
              </div>
            )}

            {currentStep === 3 && (
              <div>
                {loading ? (
                  <Loading />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                    className="contact-container"
                  >
                    <DropDown
                      label="País"
                      subTitle={pais}
                      isOpend={paisIsOpen}
                      setIsOpend={() =>
                        this.setState({
                          paisIsOpen: !paisIsOpen,
                        })
                      }
                      middle
                      shouldShowSearch
                      onKeyDown={e =>
                        this.searchOnChangeText(e, 'id', paisesInfo, 'paises')
                      }
                    >
                      {paises.map(item => (
                        <p
                          key={item.id}
                          onClick={e => {
                            this.handleSelectOnClick(e, item.id, 'pais');
                            this.setState({
                              paisIsOpen: !paisIsOpen,
                            });
                          }}
                        >
                          {item.id}
                        </p>
                      ))}
                    </DropDown>

                    {pais == 'Brasil' ? (
                      <MaskInput
                        value={cep}
                        onChange={e => this.handleChangeMaskedInput(e, 'cep')}
                        Label="CEP"
                        mask="99999-999"
                        placeholder="Digite aqui"
                        middle
                      />
                    ) : (
                      <Input
                        value={cep}
                        onChange={this.handleChangeInput}
                        Label="ZIP/Postal Code"
                        maxLength={255}
                        type="text"
                        name="cep"
                        placeholder="Digite aqui"
                        middle
                      />
                    )}

                    <Input
                      value={nome_cidade}
                      onChange={this.handleChangeInput}
                      Label="Cidade"
                      maxLength={255}
                      type="text"
                      name="nome_cidade"
                      placeholder="Digite aqui"
                      middle
                    />

                    {pais == 'Brasil' ? (
                      <DropDown
                        label="Estado"
                        subTitle={state}
                        isOpend={stateIsOpend}
                        setIsOpend={() =>
                          this.setState({
                            stateIsOpend: !stateIsOpend,
                          })
                        }
                        middle
                        shouldShowSearch
                        onKeyDown={e =>
                          this.searchOnChangeText(
                            e,
                            'initials',
                            Constants.STATES,
                            'statesArray'
                          )
                        }
                      >
                        {statesArray.map(item => (
                          <p
                            key={item.id}
                            onClick={e => {
                              this.handleSelectOnClick(
                                e,
                                item.id,
                                'cod_uf',
                                'state'
                              );
                              this.setState({
                                stateIsOpend: !stateIsOpend,
                              });
                            }}
                          >
                            {item.initials}
                          </p>
                        ))}
                      </DropDown>
                    ) : (
                      <Input
                        value={bairro}
                        onChange={this.handleChangeInput}
                        Label="Estado/Província"
                        maxLength={255}
                        type="text"
                        name="bairro"
                        placeholder="Digite aqui"
                        middle
                      />
                    )}

                    <Input
                      value={endereco}
                      onChange={this.handleChangeInput}
                      Label={
                        pais == 'Brasil'
                          ? 'Endereço (Rua)'
                          : 'Endereço (linha 1)'
                      }
                      width={pais == 'Brasil' ? '70%' : '100%'}
                      maxLength={255}
                      type="text"
                      name="endereco"
                      placeholder="Digite aqui"
                    />

                    {pais == 'Brasil' && (
                      <>
                        <Input
                          value={endereco_nro}
                          onChange={this.handleChangeInput}
                          Label="Número"
                          maxLength={255}
                          type="text"
                          name="endereco_nro"
                          placeholder="Digite aqui"
                          extraSmall
                        />
                        <Input
                          value={bairro}
                          onChange={this.handleChangeInput}
                          Label="Bairro"
                          maxLength={255}
                          type="text"
                          name="bairro"
                          placeholder="Digite aqui"
                          middle
                        />
                      </>
                    )}

                    <Input
                      value={endereco_complemento}
                      onChange={this.handleChangeInput}
                      Label={
                        pais == 'Brasil' ? 'Complemento' : 'Endereço (linha 2)'
                      }
                      maxLength={255}
                      type="text"
                      name="endereco_complemento"
                      placeholder="Digite aqui"
                      middle={pais == 'Brasil'}
                    />

                    <Input
                      value={nome_responsavel}
                      onChange={this.handleChangeInput}
                      Label="Nome do responsável"
                      maxLength={255}
                      type="text"
                      name="nome_responsavel"
                      placeholder="Digite aqui"
                      middle
                    />

                    <MaskInput
                      value={cpf_responsavel}
                      onChange={e =>
                        this.handleChangeMaskedInput(e, 'cpf_responsavel')
                      }
                      Label="CPF do responsável"
                      mask="999.999.999-99"
                      placeholder="Digite aqui"
                      middle
                    />

                    <Input
                      value={nome_parente}
                      onChange={this.handleChangeInput}
                      Label="Nome de um parente"
                      maxLength={255}
                      type="text"
                      name="nome_parente"
                      placeholder="Digite aqui"
                      middle
                    />

                    <MaskInput
                      value={tel_parente}
                      onChange={e =>
                        this.handleChangeMaskedInput(e, 'tel_parente')
                      }
                      Label="Telefone do parente"
                      type="text"
                      placeholder="Digite aqui"
                      mask="(99) 99999-9999"
                      middle
                    />

                    <DropDown
                      label="Onde nos conheceu"
                      subTitle={whereHasKnow}
                      isOpend={whereHasKnowIsOpend}
                      setIsOpend={() =>
                        this.setState({
                          whereHasKnowIsOpend: !whereHasKnowIsOpend,
                        })
                      }
                      shouldShowSearch={lista_onde_conheceu.length > 3}
                      onKeyDown={e =>
                        this.searchOnChangeText(
                          e,
                          'name',
                          lista_onde_conheceu,
                          'WhereHasMeetArray'
                        )
                      }
                    >
                      {WhereHasMeetArray.map(item => (
                        <p
                          key={item.id}
                          onClick={e => {
                            this.handleSelectOnClick(
                              e,
                              item.id,
                              'cod_onde_conheceu',
                              'whereHasKnow'
                            );
                            this.setState({
                              whereHasKnowIsOpend: !whereHasKnowIsOpend,
                            });
                          }}
                        >
                          {item.name}
                        </p>
                      ))}
                    </DropDown>
                  </div>
                )}
              </div>
            )}

            {currentStep === 4 && (
              <div className="success-container">
                <BsCheck2Circle
                  style={{ fontSize: 150, color: '#8664a7', margin: 20 }}
                />
                <span>Cadastro feito com sucesso!</span>
                <p>Obrigado por se cadastrar em nossa plataforma.</p>
              </div>
            )}
          </div>

          {!loading && (
            <div className="progress-button-container">
              <div
                className="row"
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  justifySelf: 'flex-end',
                  marginTop: 30,
                }}
              >
                {this.renderPreviousButton()}
                {this.renderNextButton()}
                {this.renderFinishButton()}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  getClinicRequest: PropTypes.func.isRequired,
  postInsertRequest: PropTypes.func.isRequired,
  register: PropTypes.shape({
    msg: PropTypes.shape({
      error: PropTypes.bool,
    }),
    clinic: PropTypes.shape({
      professionals: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = state => ({ register: state.register });

const mapDispatchToProps = dispatch =>
  bindActionCreators(RegisterAction, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Register);
