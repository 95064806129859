export const paisesInfo = [
  { ddi: '93', flag: 'AF', id: 'Afghanistan' },
  { ddi: '355', flag: 'AL', id: 'Albania' },
  { ddi: '213', flag: 'DZ', id: 'Algeria' },
  { ddi: '376', flag: 'AD', id: 'Andorra' },
  { ddi: '244', flag: 'AO', id: 'Angola' },
  { ddi: '1', flag: 'AG', id: 'Antigua and Barbuda' },
  { ddi: '54', flag: 'AR', id: 'Argentina' },
  { ddi: '374', flag: 'AM', id: 'Armenia' },
  { ddi: '297', flag: 'AW', id: 'Aruba' },
  { ddi: '61', flag: 'AU', id: 'Australia' },
  { ddi: '43', flag: 'AT', id: 'Austria' },
  { ddi: '994', flag: 'AZ', id: 'Azerbaijan' },
  { ddi: '1', flag: 'BS', id: 'Bahamas' },
  { ddi: '973', flag: 'BH', id: 'Bahrain' },
  { ddi: '880', flag: 'BD', id: 'Bangladesh' },
  { ddi: '1', flag: 'BB', id: 'Barbados' },
  { ddi: '375', flag: 'BY', id: 'Belarus' },
  { ddi: '32', flag: 'BE', id: 'Belgium' },
  { ddi: '501', flag: 'AZ', id: 'Belize' },
  { ddi: '229', flag: 'BU', id: 'Benin' },
  { ddi: '1', flag: 'BM', id: 'Bermuda' },
  { ddi: '975', flag: 'BT', id: 'Bhutan' },
  { ddi: '591', flag: 'BO', id: 'Bolivia' },
  { ddi: '387', flag: 'BA', id: 'Bosnia and Herzegovina' },
  { ddi: '267', flag: 'AW', id: 'Botswana' },
  { ddi: '55', flag: 'BR', id: 'Brasil' },
  { ddi: '246', flag: 'IO', id: 'British Indian Ocean Territory' },
  { ddi: '1', flag: 'VG', id: 'British Virgin Islands' },
  { ddi: '673', flag: 'BN', id: 'Brunei' },
  { ddi: '359', flag: 'BG', id: 'Bulgaria' },
  { ddi: '226', flag: 'BF', id: 'Burkina Faso' },
  { ddi: '257', flag: 'BI', id: 'Burundi' },
  { ddi: '855', flag: 'KH', id: 'Cambodia' },
  { ddi: '237', flag: 'CM', id: 'Cameroon' },
  { ddi: '1', flag: 'CA', id: 'Canada' },
  { ddi: '238', flag: 'CV', id: 'Cape Verde' },
  { ddi: '1', flag: 'BQ', id: 'Caribbean Netherlands' },
  { ddi: '1', flag: 'KY', id: 'Cayman Islands' },
  { ddi: '236', flag: 'CF', id: 'Central African Republic' },
  { ddi: '235', flag: 'TD', id: 'Chad' },
  { ddi: '56', flag: 'CL', id: 'Chile' },
  { ddi: '86', flag: 'CN', id: 'China' },
  { ddi: '61', flag: 'CX', id: 'Christmas Island' },
  { ddi: '61', flag: 'CC', id: 'Cocos (Keeling) Islands' },
  { ddi: '57', flag: 'CO', id: 'Colombia' },
  { ddi: '269', flag: 'KM', id: 'Comoros' },
  { ddi: '243', flag: 'CD', id: 'Congo (DRC)' },
  { ddi: '242', flag: 'CG', id: 'Congo (Republic)' },
  { ddi: '682', flag: 'CK', id: 'Cook Islands' },
  { ddi: '506', flag: 'CR', id: 'Costa Rica' },
  { ddi: '225', flag: 'CI', id: "Côte d'Ivoire" },
  { ddi: '385', flag: 'HR', id: 'Croatia' },
  { ddi: '53', flag: 'CU', id: 'Cuba' },
  { ddi: '599', flag: 'CW', id: 'Curaçao' },
  { ddi: '357', flag: 'CY', id: 'Cyprus' },
  { ddi: '420', flag: 'CZ', id: 'Czech Republic' },
  { ddi: '45', flag: 'DK', id: 'Denmark' },
  { ddi: '253', flag: 'DJ', id: 'Djibouti' },
  { ddi: '1', flag: 'DM', id: 'Dominica' },
  { ddi: '1', flag: 'DO', id: 'Dominican Republic' },
  { ddi: '593', flag: 'EC', id: 'Ecuador' },
  { ddi: '20', flag: 'EG', id: 'Egypt' },
  { ddi: '503', flag: 'SV', id: 'El Salvador' },
  { ddi: '240', flag: 'GQ', id: 'Equatorial Guinea' },
  { ddi: '291', flag: 'ER', id: 'Eritrea' },
  { ddi: '372', flag: 'EE', id: 'Estonia' },
  { ddi: '251', flag: 'ET', id: 'Ethiopia' },
  { ddi: '500', flag: 'FK', id: 'Falkland Islands' },
  { ddi: '298', flag: 'FO', id: 'Faroe Islands' },
  { ddi: '679', flag: 'FJ', id: 'Fiji' },
  { ddi: '358', flag: 'FI', id: 'Finland' },
  { ddi: '33', flag: 'FR', id: 'France' },
  { ddi: '594', flag: 'GF', id: 'French Guiana' },
  { ddi: '689', flag: 'PF', id: 'French Polynesia' },
  { ddi: '241', flag: 'GA', id: 'Gabon' },
  { ddi: '220', flag: 'GM', id: 'Gambia' },
  { ddi: '995', flag: 'GE', id: 'Georgia' },
  { ddi: '49', flag: 'DE', id: 'Germany' },
  { ddi: '233', flag: 'GH', id: 'Ghana' },
  { ddi: '350', flag: 'GI', id: 'Gibraltar' },
  { ddi: '30', flag: 'GR', id: 'Greece' },
  { ddi: '299', flag: 'GL', id: 'Greenland' },
  { ddi: '1', flag: 'GD', id: 'Grenada' },
  { ddi: '590', flag: 'GP', id: 'Guadeloupe' },
  { ddi: '1', flag: 'GU', id: 'Guam' },
  { ddi: '502', flag: 'GT', id: 'Guatemala' },
  { ddi: '44', flag: 'GG', id: 'Guernsey' },
  { ddi: '224', flag: 'GN', id: 'Guinea' },
  { ddi: '245', flag: 'GW', id: 'Guinea-Bissau' },
  { ddi: '592', flag: 'GY', id: 'Guyana' },
  { ddi: '509', flag: 'HT', id: 'Haiti' },
  { ddi: '504', flag: 'HN', id: 'Honduras' },
  { ddi: '852', flag: 'HK', id: 'Hong Kong' },
  { ddi: '36', flag: 'HU', id: 'Hungary' },
  { ddi: '354', flag: 'IS', id: 'Iceland' },
  { ddi: '91', flag: 'IN', id: 'India' },
  { ddi: '62', flag: 'ID', id: 'Indonesia' },
  { ddi: '98', flag: 'IR', id: 'Iran' },
  { ddi: '964', flag: 'IQ', id: 'Iraq' },
  { ddi: '353', flag: 'IE', id: 'Ireland' },
  { ddi: '44', flag: 'IM', id: 'Isle of Man' },
  { ddi: '972', flag: 'IL', id: 'Israel' },
  { ddi: '39', flag: 'IT', id: 'Italy' },
  { ddi: '1', flag: 'JM', id: 'Jamaica' },
  { ddi: '81', flag: 'JP', id: 'Japan' },
  { ddi: '44', flag: 'JE', id: 'Jersey' },
  { ddi: '962', flag: 'JO', id: 'Jordan' },
  { ddi: '7', flag: 'KZ', id: 'Kazakhstan' },
  { ddi: '254', flag: 'KE', id: 'Kenya' },
  { ddi: '686', flag: 'KI', id: 'Kiribati' },
  { ddi: '850', flag: 'KP', id: 'Korea (North)' },
  { ddi: '82', flag: 'KR', id: 'Korea (South)' },
  { ddi: '965', flag: 'KW', id: 'Kuwait' },
  { ddi: '996', flag: 'KG', id: 'Kyrgyzstan' },
  { ddi: '856', flag: 'LA', id: 'Laos' },
  { ddi: '371', flag: 'LV', id: 'Latvia' },
  { ddi: '961', flag: 'LB', id: 'Lebanon' },
  { ddi: '266', flag: 'LS', id: 'Lesotho' },
  { ddi: '231', flag: 'LR', id: 'Liberia' },
  { ddi: '218', flag: 'LY', id: 'Libya' },
  { ddi: '423', flag: 'LI', id: 'Liechtenstein' },
  { ddi: '370', flag: 'LT', id: 'Lithuania' },
  { ddi: '352', flag: 'LU', id: 'Luxembourg' },
  { ddi: '853', flag: 'MO', id: 'Macao' },
  { ddi: '389', flag: 'MK', id: 'Macedonia' },
  { ddi: '261', flag: 'MG', id: 'Madagascar' },
  { ddi: '265', flag: 'MW', id: 'Malawi' },
  { ddi: '60', flag: 'MY', id: 'Malaysia' },
  { ddi: '960', flag: 'MV', id: 'Maldives' },
  { ddi: '223', flag: 'ML', id: 'Mali' },
  { ddi: '356', flag: 'MT', id: 'Malta' },
  { ddi: '692', flag: 'MH', id: 'Marshall Islands' },
  { ddi: '596', flag: 'MQ', id: 'Martinique' },
  { ddi: '222', flag: 'MR', id: 'Mauritania' },
  { ddi: '230', flag: 'MU', id: 'Mauritius' },
  { ddi: '262', flag: 'YT', id: 'Mayotte' },
  { ddi: '52', flag: 'MX', id: 'Mexico' },
  { ddi: '691', flag: 'FM', id: 'Micronesia' },
  { ddi: '373', flag: 'MD', id: 'Moldova' },
  { ddi: '377', flag: 'MC', id: 'Monaco' },
  { ddi: '976', flag: 'MN', id: 'Mongolia' },
  { ddi: '382', flag: 'ME', id: 'Montenegro' },
  { ddi: '1', flag: 'MS', id: 'Montserrat' },
  { ddi: '212', flag: 'MA', id: 'Morocco' },
  { ddi: '258', flag: 'MZ', id: 'Mozambique' },
  { ddi: '95', flag: 'MM', id: 'Myanmar' },
  { ddi: '264', flag: 'NA', id: 'Namibia' },
  { ddi: '674', flag: 'NR', id: 'Nauru' },
  { ddi: '977', flag: 'NP', id: 'Nepal' },
  { ddi: '31', flag: 'NL', id: 'Netherlands' },
  { ddi: '599', flag: 'AN', id: 'Netherlands Antilles' },
  { ddi: '687', flag: 'NC', id: 'New Caledonia' },
  { ddi: '64', flag: 'NZ', id: 'New Zealand' },
  { ddi: '505', flag: 'NI', id: 'Nicaragua' },
  { ddi: '227', flag: 'NE', id: 'Niger' },
  { ddi: '234', flag: 'NG', id: 'Nigeria' },
  { ddi: '683', flag: 'NU', id: 'Niue' },
  { ddi: '672', flag: 'NF', id: 'Norfolk Island' },
  { ddi: '1', flag: 'MP', id: 'Northern Mariana Islands' },
  { ddi: '47', flag: 'NO', id: 'Norway' },
  { ddi: '968', flag: 'OM', id: 'Oman' },
  { ddi: '92', flag: 'PK', id: 'Pakistan' },
  { ddi: '680', flag: 'PW', id: 'Palau' },
  { ddi: '970', flag: 'PS', id: 'Palestinian Territories' },
  { ddi: '507', flag: 'PA', id: 'Panama' },
  { ddi: '675', flag: 'PG', id: 'Papua New Guinea' },
  { ddi: '595', flag: 'PY', id: 'Paraguay' },
  { ddi: '51', flag: 'PE', id: 'Peru' },
  { ddi: '63', flag: 'PH', id: 'Philippines' },
  { ddi: '48', flag: 'PL', id: 'Poland' },
  { ddi: '351', flag: 'PT', id: 'Portugal' },
  { ddi: '1', flag: 'PR', id: 'Puerto Rico' },
  { ddi: '974', flag: 'QA', id: 'Qatar' },
  { ddi: '262', flag: 'RE', id: 'Réunion' },
  { ddi: '40', flag: 'RO', id: 'Romania' },
  { ddi: '7', flag: 'RU', id: 'Russia' },
  { ddi: '250', flag: 'RW', id: 'Rwanda' },
  { ddi: '590', flag: 'BL', id: 'Saint Barthélemy' },
  { ddi: '290', flag: 'SH', id: 'Saint Helena' },
  { ddi: '1', flag: 'KN', id: 'Saint Kitts and Nevis' },
  { ddi: '1', flag: 'LC', id: 'Saint Lucia' },
  { ddi: '590', flag: 'MF', id: 'Saint Martin' },
  { ddi: '508', flag: 'PM', id: 'Saint Pierre and Miquelon' },
  { ddi: '1', flag: 'VC', id: 'Saint Vincent and the Grenadines' },
  { ddi: '685', flag: 'WS', id: 'Samoa' },
  { ddi: '378', flag: 'SM', id: 'San Marino' },
  { ddi: '239', flag: 'ST', id: 'São Tomé and Príncipe' },
  { ddi: '966', flag: 'SA', id: 'Saudi Arabia' },
  { ddi: '221', flag: 'SN', id: 'Senegal' },
  { ddi: '381', flag: 'RS', id: 'Serbia' },
  { ddi: '248', flag: 'SC', id: 'Seychelles' },
  { ddi: '232', flag: 'SL', id: 'Sierra Leone' },
  { ddi: '65', flag: 'SG', id: 'Singapore' },
  { ddi: '421', flag: 'SK', id: 'Slovakia' },
  { ddi: '386', flag: 'SI', id: 'Slovenia' },
  { ddi: '677', flag: 'SB', id: 'Solomon Islands' },
  { ddi: '252', flag: 'SO', id: 'Somalia' },
  { ddi: '27', flag: 'ZA', id: 'South Africa' },
  {
    ddi: '500',
    flag: 'GS',
    id: 'South Georgia and the South Sandwich Islands',
  },
  { ddi: '34', flag: 'ES', id: 'Spain' },
  { ddi: '94', flag: 'LK', id: 'Sri Lanka' },
  { ddi: '249', flag: 'SD', id: 'Sudan' },
  { ddi: '597', flag: 'SR', id: 'Suriname' },
  { ddi: '47', flag: 'SJ', id: 'Svalbard and Jan Mayen' },
  { ddi: '268', flag: 'SZ', id: 'Swaziland' },
  { ddi: '46', flag: 'SE', id: 'Sweden' },
  { ddi: '41', flag: 'CH', id: 'Switzerland' },
  { ddi: '963', flag: 'SY', id: 'Syria' },
  { ddi: '886', flag: 'TW', id: 'Taiwan' },
  { ddi: '992', flag: 'TJ', id: 'Tajikistan' },
  { ddi: '255', flag: 'TZ', id: 'Tanzania' },
  { ddi: '66', flag: 'TH', id: 'Thailand' },
  { ddi: '670', flag: 'TL', id: 'Timor-Leste' },
  { ddi: '228', flag: 'TG', id: 'Togo' },
  { ddi: '690', flag: 'TK', id: 'Tokelau' },
  { ddi: '676', flag: 'TO', id: 'Tonga' },
  { ddi: '1', flag: 'TT', id: 'Trinidad and Tobago' },
  { ddi: '216', flag: 'TN', id: 'Tunisia' },
  { ddi: '90', flag: 'TR', id: 'Turkey' },
  { ddi: '993', flag: 'TM', id: 'Turkmenistan' },
  { ddi: '1', flag: 'TC', id: 'Turks and Caicos Islands' },
  { ddi: '688', flag: 'TV', id: 'Tuvalu' },
  { ddi: '256', flag: 'UG', id: 'Uganda' },
  { ddi: '380', flag: 'UA', id: 'Ukraine' },
  { ddi: '971', flag: 'AE', id: 'United Arab Emirates' },
  { ddi: '44', flag: 'GB', id: 'United Kingdom' },
  { ddi: '1', flag: 'US', id: 'United States' },
  { ddi: '598', flag: 'UY', id: 'Uruguay' },
  { ddi: '998', flag: 'UZ', id: 'Uzbekistan' },
  { ddi: '678', flag: 'VU', id: 'Vanuatu' },
  { ddi: '379', flag: 'VA', id: 'Vatican City' },
  { ddi: '58', flag: 'VE', id: 'Venezuela' },
  { ddi: '84', flag: 'VN', id: 'Vietnam' },
  { ddi: '1', flag: 'VI', id: 'Virgin Islands (U.S.)' },
  { ddi: '681', flag: 'WF', id: 'Wallis and Futuna' },
  { ddi: '967', flag: 'YE', id: 'Yemen' },
  { ddi: '260', flag: 'ZM', id: 'Zambia' },
  { ddi: '263', flag: 'ZW', id: 'Zimbabwe' },
];
