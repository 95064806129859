import React from 'react';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import './TextfieldCountry.css';

export function TextfieldCountry({
  label = '',
  value = '',
  onChange = () => {},
  isValid = () => {},
  placeholder = '+55 (__) _____-____',
  error = false,
  ddiEditavel = true,
  required = false,
  disableCountryCode = false,
  enableAreaCodes = false,
  enableLongNumbers = false,
  onBlur,
}) {
  function validaTelefone(num, mask) {
    const numeroNormalizado1 = num.replace(/\d/g, '.');
    return numeroNormalizado1 === mask;
  }

  const handlePhoneChange = (phoneValue, data, event, formattedValue) => {
    onChange(phoneValue, data, event, formattedValue);

    if (isValid) {
      isValid(validaTelefone(formattedValue, data.format));
    }
  };

  return (
    <div className={`InputWrapper ${error ? 'error' : ''}`}>
      {label && (
        <label>
          <div className="LabelWrapper">
            <p
              style={{
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                lineHeight: '24px',
                fontSize: '16px',
                fontWeight: '400',
                color: '#3D3D3D',
              }}
            >
              {label}
              {label.length > 0 &&
                label[label.length - 1].trim() !== ':' &&
                ':'}
            </p>

            {required && (
              <p
                style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  lineHeight: '24px',
                  fontSize: '16px',
                  fontWeight: '400',
                  color: '#F44336',
                }}
              >
                *
              </p>
            )}
          </div>
        </label>
      )}

      <PhoneInput
        country="br"
        enableAreaCodes={enableAreaCodes}
        countryCodeEditable={ddiEditavel}
        value={value}
        onChange={handlePhoneChange}
        localization={{ Brazil: 'Brasil' }}
        masks={{ br: '(..) .....-....' }}
        placeholder={placeholder}
        disableCountryCode={disableCountryCode}
        enableLongNumbers={enableLongNumbers}
        onBlur={onBlur}
      />
    </div>
  );
}
